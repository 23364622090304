<template>
	<div v-if="dialogVisible">
		<el-dialog title="修改部门" :visible.sync="dialogVisible" width="65%">			
			<div class="addbox">
				<div class="mylefttbale" style="height: 71vh;padding-top: 10px;box-sizing: border-box;">
					<el-table
						height="650"
					    :data="tableData"
					    style="width: 100%;margin-bottom: 20px;border-left: 1px solid #eee;"
					    row-key="id"
					    border
						:stripe="true"
						:indent="50"
						 align="left"
					    :tree-props="{children: 'children'}">
						
					    <el-table-column prop="name" label="名称" width="" align="left"></el-table-column>
						
						
						<el-table-column prop="weigh" label="排序" width="100"></el-table-column>
						
						<el-table-column fixed="right" label="操作" width="160">
							<template slot-scope="scope">
								<el-button @click="showedit(scope.row)" type="primary" size="mini">编辑</el-button>
								<el-button @click="deleterow(scope.row)" type="danger" size="mini">删除</el-button>
							</template>
						</el-table-column>
					  </el-table>
				</div>
			</div>
			<div class="btnbox">
				<el-button size="mini" class="font14" @click="dialogVisible=false">关闭</el-button>			
			</div>
		</el-dialog>
		
		
		<detail ref="mydetail"></detail>
	</div>

</template>

<script>
	import detail from './detail.vue'
	export default {
		components:{ detail },
		data() {
			return {
				dialogVisible: false,
				tableData: [],
			}
		},
		created() {
			
		},
		methods: {
			showedit(row){
				this.$refs.mydetail.fathername = this.findNameById(this.tableData,row.pid)
				this.$refs.mydetail.form = JSON.parse(JSON.stringify(row))
				delete this.$refs.mydetail.form.addtime
				delete this.$refs.mydetail.form.children
				this.$refs.mydetail.dialogVisible = true
				this.$refs.mydetail.isedite = true
			},
			loadNode(){
				this.$post({
					url:'/api/organizational/quan',
					params:{
						title:'',						
					}
				}).then((res)=>{
					this.tableData = res.list
				})
			},
			deleterow(row) {
			    this.$confirm('确认删除吗？')
			        .then(_ => {
			            this.$post({
			                url: '/api/organizational/del',
			                params: {
			                    ids: row.id
			                }
			            }).then((res) => {
			                this.$message.success('删除成功');
			                this.loadNode()
			
			            })
			        })
			        .catch(_ => { });
			
			},
			findNameById(data, targetId) {
				for (let item of data) {
				    if (item.id === targetId) {
				      return item.name;
				    }
				    if (item.children) {
				      const result = this.findNameById(item.children, targetId);
				      if (result) {
				        return result;
				      }
				    }
				  }
				return null;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.item_line{
		display: flex;
		align-items: center;
		margin-bottom: 30px;
		.tlename{
			width: 120px;
			font-size: 14px;
			
			text-align: right;
			color: #333;
		}
		.bticon{
			width: 10px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 34px;
			font-size: 26px;
			color: red;
		}
	}
	.infobox{
		width: 100%;
		padding-left: 50px;
		padding-right: 30px;
		.contbox{
			display: flex;
			padding:20px 40px;
			height: 750px;
			transition: all 0.5s; 
			overflow: hidden;
		}
		.colosecont{
			height: 0;
			padding: 0;
		}
		.title{
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 20px;
			color: #ec4a4a;
			border-bottom: 1px solid #ec4a4a;
		}
	}
	.addbox {
		width: 100%;
		overflow-y: auto;
		padding: 30px;
		background-color: #fff;
		
	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		padding: 10px 0;
		justify-content: flex-end;
	}
	::v-deep .mylefttbale .el-table .el-table__cell{
		text-align: left !important;
	}
	::v-deep .mylefttbale .is-left{
		padding-left: 20px;
	}
	::v-deep .el-form-item__error{
		padding-top: 0;
	}
	::v-deep .el-form-item {
		margin-bottom: 10px;
	}

	::v-deep .el-form-item__label {
		color: #999;
		font-size: 14px;
	}

	::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
		content: '' !important;
	}

	::v-deep .el-input--small .el-input__icon {
		color: #333;
		font-weight: bold;
	}

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding: 10px;
		padding-top: 0px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}
</style>